import showPropertyRoute from "./ShowProperty/show.property.route";

export default {
  routes: [
    {
      path: "/properties",
      component: () => import("./PropertiesIndex.vue"),
      redirect: { name: "properties" },
      meta: {
        title: "Properties",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "properties",
          component: () => import("./WrapperProperties.vue")
        },
        {
          path: "create",
          name: "create-property",
          component: () => import("./CreateProperty/CreateProperty.vue"),
          meta: {
            title: "Create Property",
            roles: [""],
            isEdit: false
          }
        },
        {
          path: "edit/:id",
          name: "edit-property",
          component: () => import("./CreateProperty/CreateProperty.vue"),
          meta: {
            title: "Edit Property",
            roles: [""],
            isEdit: true
          }
        },
        {
          path: "",
          name: "show-property-index",
          component: () => import("./ShowProperty/IndexShowProperty.vue"),
          redirect: { name: "show-property" },
          children: [...showPropertyRoute.routes]
        }
      ]
    }
  ]
};
