export default {
  routes: [
    {
      path: "/communication",
      name: "communication",
      component: () => import("./WrapperCommunication.vue"),
      redirect: { name: "communication-message" },
      meta: {
        title: "communication",
        roles: [""]
      },
      children: [
        {
          path: "messages",
          name: "communication-message",
          component: () => import("./CommunicationMessage.vue")
        },
        {
          path: "support-centre",
          name: "communication-support-centre",
          component: () => import("./CommunicationSupportCentre.vue")
        },
        {
          path: "announcements",
          name: "communication-announcements",
          component: () => import("./CommunicationAnnouncements.vue")
        }
      ]
    }
  ]
};
