import vendorsRoute from "./Vendors/vendors.route";
import teamsRoute from "./Teams/teams.route";

export default {
  routes: [
    {
      path: "teams-vendors",
      component: () => import("./WrapperVendorsTeams.vue"),
      redirect: { name: "teams" },
      meta: {
        title: "Vendors & Teams",
        roles: [""],
      },
      children: [...teamsRoute.routes, ...vendorsRoute.routes],
    },
  ],
};
