export default {
  routes: [
    {
      path: "/asset",
      component: () => import("./AssetIndex.vue"),
      meta: {
        title: "Asset Management",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "assets-management",
          component: () => import("./WrapperAsset.vue")
        },
        {
          path: "create",
          name: "create-asset",
          component: () => import("./CreateAsset.vue"),
          meta: {
            roles: []
          }
        },
        {
          path: "edit/:id",
          name: "edit-asset",
          component: () => import("./CreateAsset.vue"),
          meta: {
            isEdit: true,
            roles: []
          }
        },
        {
          path: ":id",
          name: "asset-details",
          component: () => import("./AssetDetailsWrapper.vue"),
          meta: {
            title: "Asset Management",
            roles: [""]
          },
          redirect: { name: "info" },
          children: [
            {
              path: "info",
              name: "info",
              component: () => import("./AssetDetails.vue"),
              meta: {
                title: "Asset Management",
                roles: [""]
              }
            },

            {
              path: "inventory-line",
              name: "inventory-line",
              component: () => import("./AssetInventoryLine.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            },
            {
              path: "performance",
              name: "performance",
              component: () => import("./AssetPerformance.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            },
            {
              path: "work-orders",
              name: "work-orders",
              component: () => import("./AssetWorkOrders.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            }
          ]
        }
      ]
    }
  ]
};
