export default {
  routes: [
    {
      path: "/dashboard",
      component: () => import("./WrapperHome.vue"),
      meta: {
        title: "Dashboard",
        roles: [""]
      },
      redirect: { name: "dashboard" },
      children: [
        {
          path: "overview",
          name: "dashboard",
          component: () => import("./Dashboard/Dashboard.vue"),
          meta: {
            title: "Dashboard",
            roles: [""]
          }
        },
        {
          path: "reports-details",
          name: "reports-details",
          component: () => import("./ReportingDetails/ReportingDetails.vue"),
          meta: {
            title: "Reporting Details",
            roles: [""]
          }
        },
        {
          path: "recent-activity",
          name: "recent-activity",
          component: () => import("./RecentActivity/RecentActivity.vue"),
          meta: {
            title: "Recent Activity",
            roles: [""]
          }
        },
        {
          path: "import-export",
          name: "import-export",
          component: () => import("./ImportExport/ImportExport.vue"),
          meta: {
            title: "Import Export",
            roles: [""]
          }
        }
      ]
    }
  ]
};
