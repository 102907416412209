import showSiteRoute from "./ShowSite/show.site.route";

export default {
  routes: [
    {
      path: "/sites",
      component: () => import("./SitesIndex.vue"),
      redirect: { name: "sites" },
      meta: {
        title: "Sites",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "sites",
          component: () => import("./WrapperSites.vue")
        },
        {
          path: "create",
          name: "create-site",
          component: () => import("./CreateSite/CreateSite.vue"),
          meta: {
            title: "Create Site",
            roles: [""],
            isEdit: false
          }
        },
        {
          path: "edit/:id",
          name: "edit-site",
          component: () => import("./CreateSite/CreateSite.vue"),
          meta: {
            title: "Edit Site",
            roles: [""],
            isEdit: true
          }
        },
        {
          path: "create/:id",
          name: "show-create-site",
          component: () => import("./CreateSite/ShowSiteCreated.vue"),
          meta: {
            title: "show Create Site",
            roles: [""],
            isEdit: false
          }
        },
        {
          path: "",
          name: "show-site-index",
          component: () => import("./ShowSite/IndexShowSite.vue"),
          redirect: { name: "show-site" },
          children: [...showSiteRoute.routes]
        }
      ]
    }
  ]
};
