import authRoute from "@/views/Auth/auth.route";
import dashboardRoute from "@/views/Dashboard/dashboard.route";
import { createRouter, createWebHistory } from "vue-router";

import {
  authGuard,
  guestGuard,
  isVendor,
  isAdmin,
} from "@/helpers/router.helper";
import adminRoute from "../views/Admin/admin.route";
import vendorRoute from "../views/Vendor/vendor.route";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      components: {
        default: () => import("@/views/Auth/WrapperAuth.vue"),
      },
      redirect: { name: "login" },
      children: [...authRoute.routes],
      beforeEnter: authGuard,
    },
    {
      path: "/connect",
      name: "connect",
      components: {
        default: () => import("@/views/Dashboard/WrapperDashboard.vue"),
      },
      beforeEnter: guestGuard,
      children: [...dashboardRoute.routes],
    },
    {
      path: "/admin",
      components: {
        default: () => import("@/views/Admin/WrapperAdmin.vue"),
      },
      name: "admin-dashboard",
      redirect: { name: "admin-owners" },
      beforeEnter: isAdmin,
      children: [...adminRoute.routes],
    },
    {
      path: "/vendor",
      components: {
        default: () => import("@/views/Vendor/WrapperVendor.vue"),
      },
      name: "vendor-dashboard",
      redirect: { name: "vendor-owners" },
      beforeEnter: isVendor,
      children: [...vendorRoute.routes],
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
