import showTeamsRoute from "./show.teams.route";

export default {
  routes: [
    {
      path: "/teams",
      name: "teams-index",
      component: () => import("./TeamsIndex.vue"),
      meta: {
        title: "Teams",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "teams",
          component: () => import("./WrapperTeams.vue"),
          redirect: { name: "teams-list-view" },
          children: [
            {
              path: "calendar",
              name: "teams-calendar-view",
              component: () =>
                import("@/views/Dashboard/WorkOrders/CorrectiveOrders/WrapperCorrective.vue"),
              meta: {
                title: "Teams",
                roles: [""]
              }
            },
            {
              path: "list",
              name: "teams-list-view",
              component: () => import("./TeamsList.vue"),
              meta: {
                title: "Teams",
                roles: [""]
              }
            },
            {
              path: "cards",
              name: "teams-cards-view",
              component: () => import("./TeamsListCards.vue"),
              meta: {
                title: "Teams",
                roles: [""]
              }
            }
          ]
        },
        {
          path: "create",
          name: "create-teams",
          component: () => import("./CreateTeam.vue")
        },
        {
          path: "edit/:id",
          name: "edit-teams",
          component: () => import("./CreateTeam.vue"),
          meta: {
            isEdit: true
          }
        },
        {
          path: "review/:id",
          name: "review-teams",
          component: () => import("./BeforeCreateMember.vue")
        },
        {
          path: "create-members/:id",
          name: "create-teams-members",
          component: () => import("./CreateTeamMembers.vue")
        },
        {
          path: "edit-members/:id",
          name: "edit-teams-members",
          component: () => import("./CreateTeamMembers.vue"),
          meta: {
            isEdit: true
          }
        },
        {
          path: "",
          name: "show-teams-index",
          component: () => import("./IndexShowTeams.vue"),
          redirect: { name: "show-teams" },
          children: [...showTeamsRoute.routes]
        }
      ]
    }
  ]
};
