import { extractKeyword } from "./url.helper";

export const authGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey(
    `${extractKeyword(window.location.href)}_mc_token`,
  );
  if (tokenExists) {
    // Token exists, redirect to contracts page
    if (extractKeyword(window.location.href) === "admin") {
      next({ name: "admin-dashboard" });
    } else if (extractKeyword(window.location.href) == "vendor") {
      next({ name: "vendor-dashboard" });
    } else {
      next({ name: "dashboard" });
    }
  } else {
    // Token doesn't exist, allow navigation to the requested route
    next();
  }
};

export const guestGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey(
    `${extractKeyword(window.location.href)}_mc_token`,
  );
  if (tokenExists) {
    next();
  } else {
    next({ name: "login" });
  }
};

export const isVendor = (to, from, next) => {
  const tokenExists = $cookies.isKey(`vendor_mc_token`);
  if (tokenExists) {
    console.log("object");
    next();
  } else {
    next({ name: "login" });
  }
};

export const isAdmin = (to, from, next) => {
  const tokenExists = $cookies.isKey(`admin_mc_token`);
  if (tokenExists) {
    console.log("object");
    next();
  } else {
    next({ name: "login" });
  }
};
