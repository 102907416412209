export default {
  routes: [
    {
      path: "/parts-inventory",
      name: "parts-inventory-index",
      component: () => import("./PartsInventoryIndex.vue"),
      meta: {
        title: "Parts & Inventory",
        roles: []
      },
      children: [
        {
          path: "",
          name: "parts-inventory",
          component: () => import("./WrapperPartsInventory.vue")
        },
        {
          path: "create",
          name: "create-part",
          component: () => import("./CreatePart.vue"),
          meta: {
            roles: []
          }
        },
        {
          path: "edit/:id",
          name: "edit-part",
          component: () => import("./CreatePart.vue"),
          meta: {
            isEdit: true,
            roles: []
          }
        },
        {
          path: ":id",
          name: "parts-details",
          component: () => import("./PartDetailsWrapper.vue"),
          meta: {
            title: "details",
            roles: [""]
          },
          redirect: { name: "details" },
          children: [
            {
              path: "details",
              name: "details",
              component: () => import("./PartDetails.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            },
            {
              path: "inventory",
              name: "inventory",
              component: () => import("./PartInventoryLine.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            },
            {
              path: "activity",
              name: "activity",
              component: () => import("./PartActivity.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            },
            {
              path: "orders",
              name: "orders",
              component: () => import("./PartWorkOrders.vue"),
              meta: {
                title: "Asset Management",
                roles: []
              }
            }
          ]
        }
      ]
    }
  ]
};
