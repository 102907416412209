export default {
  routes: [
    {
      path: "/requests",
      name: "requests",
      component: () => import("./RequestsIndex.vue"),
      meta: {
        title: "requests",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "requests-index",
          component: () => import("./WrapperRequests.vue"),
          meta: {
            title: "requests",
            roles: [""]
          },
          redirect: { name: "requests-list-view" },

          children: [
            {
              path: "list",
              name: "requests-list-view",
              component: () => import("./RequestsList.vue"),
              meta: {
                title: "requests",
                roles: [""]
              }
            },
            {
              path: "board",
              name: "requests-board-view",
              component: () => import("./RequestsBoard.vue"),
              meta: {
                title: "requests",
                roles: [""]
              }
            }
          ]
        },
        {
          path: "create",
          name: "requests-create",
          component: () => import("./CreateSnapRequests.vue"),
          meta: {
            title: "requests",
            roles: [""]
          }
        },
        {
          path: ":id",
          name: "requests-view",
          component: () => import("./ShowRequest.vue"),
          meta: {
            title: "requests",
            roles: [""]
          }
        }
      ]
    }
  ]
};
