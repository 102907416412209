import { defineStore } from "pinia";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";

import { palette, updatePrimaryPalette } from "@primevue/themes";

export const useThemeStore = defineStore("theme", {
  state: () => ({
    theme: "light",
    color: "#1e7af0"
  }),
  getters: {},
  actions: {
    changeThemeColor(color) {
      // change primary color
      document.documentElement.style.setProperty("--primary-color", color);

      // change tailwind color
      const fullConfig = resolveConfig(tailwindConfig);
      fullConfig.theme.colors.primary = color;

      // change primevue color
      const primary = palette(color);
      updatePrimaryPalette(primary);
    }
  }
});
