import showVendorsRoute from "./show.vendors.route";

export default {
  routes: [
    {
      path: "/vendors",
      name: "vendors-index",
      component: () => import("./VendorsIndex.vue"),
      meta: {
        title: "Vendors & Teams",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "vendors",
          component: () => import("./WrapperVendors.vue")
        },
        {
          path: "create",
          name: "create-vendor",
          component: () => import("./CreateVendor.vue")
        },
        {
          path: "edit/:id",
          name: "edit-vendor",
          component: () => import("./CreateVendor.vue"),
          meta: {
            isEdit: true
          }
        },
        {
          path: "",
          name: "show-vendors-index",
          component: () => import("./IndexShowVendor.vue"),
          redirect: { name: "show-vendors" },
          children: [...showVendorsRoute.routes]
        }
      ]
    }
  ]
};
