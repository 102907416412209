export default {
  routes: [
    {
      path: "",
      name: "vendor-owners",
      component: () => import("./WrapperOwners.vue"),
      meta: {
        title: "Owners",
        roles: [""],
      },
      redirect: { name: "vendor-owners-list" },
      children: [
        {
          path: "/owners",
          name: "vendor-owners-list",
          component: () => import("./OwnersList.vue"),
          meta: {
            title: "owners",
            roles: [""],
          },
        },
      ],
    },
  ],
};
