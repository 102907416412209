export default {
  routes: [
    {
      path: "budget-settings",
      name: "budget-settings",
      component: () => import("./BudgetSettings.vue"),
      meta: {
        title: "budget-settings",
        roles: [""],
      },
    },
    {
      path: "budget-performance",
      name: "budget-performance",
      component: () => import("./BudgetPerformance.vue"),
      meta: {
        title: "budget-performance",
        roles: [""],
      },
    },
  ],
};
