import aside from "./aside";
import errors from "./errors";
import global from "./global";
import login from "./login";

export default {
  ...global,
  ...errors,
  ...aside,
  ...login,
};
