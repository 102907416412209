export default {
  routes: [
    {
      path: ":id",
      name: "show-procedure",
      component: () => import("./WrapperShowProcedure.vue"),
      meta: {
        title: "Library",
        roles: [""],
      },
      redirect: { name: "show-procedure-details" },
      children: [
        {
          path: "details",
          name: "show-procedure-details",
          component: () => import("./ProcedureDetails.vue"),
          meta: {
            title: "Library",
            roles: [""],
          },
        },
        {
          path: "assigned",
          name: "show-procedure-assigned",
          component: () => import("./ProcedureAssigned.vue"),
          meta: {
            title: "Library",
            roles: [""],
          },
        },
      ],
    },
  ],
};
