export default {
  routes: [
    {
      path: "/users",
      component: () => import("./index.vue"),
      meta: {
        title: "Users",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "users",
          component: () => import("./WrapperUsers.vue"),
          redirect: { name: "all_users" },
          children: [
            {
              path: "all",
              name: "all_users",
              component: () => import("./UsersList.vue"),
              meta: {
                roles: [""]
              }
            },
            {
              path: "roles",
              name: "user_roles",
              component: () => import("./UserRoles.vue"),
              meta: {
                roles: [""]
              }
            },
            {
              path: "departments",
              name: "departments",
              component: () => import("./Departments.vue"),
              meta: {
                roles: [""]
              }
            }
          ]
        },
        {
          path: "create",
          name: "create-user",
          component: () => import("./NewUser.vue"),
          meta: {
            roles: [""]
          }
        },
        {
          path: ":id/edit",
          name: "edit-user",
          props: true,
          component: () => import("./NewUser.vue"),
          meta: {
            roles: [""],
            isEdit: true
          }
        },
        {
          path: ":id",
          component: () => import("./details/UserDetailsWrapper.vue"),
          redirect: "details",
          props: true,
          meta: {
            roles: [""]
          },
          children: [
            {
              path: "details",
              name: "user-details",
              component: () => import("./details/UserInfo.vue"),
              meta: {
                roles: [""]
              }
            },
            {
              path: "activity-log",
              name: "user-activity-log",
              component: () => import("./details/ActivityLog.vue"),
              meta: {
                roles: [""]
              }
            }
          ]
        }
      ]
    }
  ]
};
