export default {
  routes: [
    {
      path: ":id",
      name: "show-category",
      component: () => import("./WrapperShowCategory.vue"),
      meta: {
        title: "Library",
        roles: [""],
      },
      redirect: { name: "show-category-details" },
      children: [
        {
          path: "details",
          name: "show-category-details",
          component: () => import("./CategoryDetails.vue"),
          meta: {
            title: "Library",
            roles: [""],
          },
        },
        {
          path: "orders",
          name: "show-orders-products",
          component: () => import("./CategoryOrders.vue"),
          meta: {
            title: "Library",
            roles: [""],
          },
        },
      ],
    },
  ],
};
