import showUnitRoute from "./ShowUnit/show.unit.route";

export default {
  routes: [
    {
      path: "/units",
      component: () => import("./UnitsIndex.vue"),
      redirect: { name: "units" },
      meta: {
        title: "units",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "units",
          component: () => import("./WrapperUnits.vue")
        },
        {
          path: "create",
          name: "create-unit",
          component: () => import("./CreateUnits/CreateUnits.vue"),
          meta: {
            title: "Create unit",
            roles: [""],
            isEdit: false
          }
        },
        {
          path: "edit/:id",
          name: "edit-unit",
          component: () => import("./CreateUnits/CreateUnits.vue"),
          meta: {
            title: "Edit unit",
            roles: [""],
            isEdit: true
          }
        },
        {
          path: "",
          name: "show-unit-index",
          component: () => import("./ShowUnit/IndexShowUnit.vue"),
          redirect: { name: "show-unit" },
          children: [...showUnitRoute.routes]
        }
      ]
    }
  ]
};
