export default {
  routes: [
    {
      path: ":id",
      name: "show-site",
      component: () => import("./WrapperShowSite.vue"),
      meta: {
        title: "Sites",
        roles: [""],
      },
      redirect: { name: "show-site-details" },
      children: [
        {
          path: "details",
          name: "show-site-details",
          component: () => import("./ShowSiteDetails.vue"),
          meta: {
            title: "Site",
            roles: [""],
          },
        },
        {
          path: "customers",
          name: "show-site-customers",
          component: () => import("./ShowSiteCustomers.vue"),
          meta: {
            title: "Site",
            roles: [""],
          },
        },
        {
          path: "properties",
          name: "show-site-properties",
          component: () => import("./ShowSiteProperties.vue"),
          meta: {
            title: "Site",
            roles: [""],
          },
        },
        {
          path: "work-order",
          name: "show-site-work-order",
          component: () => import("./ShowSiteWorkOrder.vue"),
          meta: {
            title: "Site",
            roles: [""],
          },
        },
      ],
    },
  ],
};
