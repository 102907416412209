import showCorrectiveRoute from "./ShowCorrective/show.corrective.route";

export default {
  routes: [
    {
      path: "/corrective",
      name: "index",
      component: () => import("./CorrectiveIndex"),
      redirect: { name: "corrective" },
      meta: {
        title: "corrective",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "corrective",
          component: () => import("./WrapperCorrective.vue"),
          meta: {
            title: "corrective",
            roles: [""]
          },
          redirect: { name: "corrective-calendar-view" },
          children: [
            {
              path: "calendar",
              name: "corrective-calendar-view",
              component: () => import("./CalendarView/CorrectiveCalendar.vue"),
              meta: {
                title: "calendar",
                roles: [""]
              }
            },
            {
              path: "list",
              name: "corrective-list-view",
              component: () => import("./ListView/CorrectiveList.vue"),
              meta: {
                title: "list",
                roles: [""]
              }
            },
            {
              path: "board",
              name: "corrective-board-view",
              component: () => import("./BoardView/CorrectiveBoard.vue"),
              meta: {
                title: "board",
                roles: [""]
              }
            }
          ]
        },
        {
          path: ":id",
          name: "wrapper-show-corrective",
          component: () => import("./ShowCorrective/WrapperShowCorrective.vue"),
          props: true,
          meta: {
            title: "detail",
            roles: [""]
          },
          redirect: { name: "corrective-details" },
          children: [...showCorrectiveRoute.routes]
        },
        {
          path: "create",
          name: "corrective-create",
          component: () => import("./CreateCorrective/CreateCorrective.vue"),
          meta: {
            title: "create",
            roles: [""],
            isEdit: false
          }
        },
        {
          path: "edit/:id",
          name: "corrective-edit",
          component: () => import("./CreateCorrective/CreateCorrective.vue"),
          meta: {
            title: "Edit",
            roles: [""],
            isEdit: true
          }
        }
      ]
    }
  ]
};
