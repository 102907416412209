export default {
  routes: [
    {
      path: "pl-statements",
      name: "pl-statements",
      component: () => import("./PLStatements.vue"),
      meta: {
        title: "P and L Statements",
        roles: [""],
      },
    },
    {
      path: "balance-sheet",
      name: "balance-sheet",
      component: () => import("./BalanceSheet.vue"),
      meta: {
        title: "Balance Sheets",
        roles: [""],
      },
    },
    {
      path: "accounts-receivable",
      name: "accounts-receivable",
      component: () => import("./AccountsPayable.vue"),
      meta: {
        title: "Accounts Receivable",
        roles: [""],
      },
    },
    {
      path: "accounts-payable",
      name: "accounts-payable",
      component: () => import("./AccountsPayable.vue"),
      meta: {
        title: "Accounts Payable",
        roles: [""],
      },
    },
    {
      path: "revenue-customer",
      name: "revenue-customer",
      component: () => import("./RevenueCustomer.vue"),
      meta: {
        title: "Revenue by Customer",
        roles: [""],
      },
    },
  ],
};
