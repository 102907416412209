export default {
  routes: [
    {
      path: "/ppm",
      name: "ppm-index",
      component: () => import("./PPMIndex.vue"),
      meta: {
        title: "Work Orders",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "ppm",
          component: () => import("./WrapperPPM.vue"),
          meta: {
            title: "Work Orders",
            roles: [""]
          },
          redirect: { name: "ppm-calendar-view" },
          children: [
            {
              path: "calendar",
              name: "ppm-calendar-view",
              component: () => import("./CalendarView/PPMCalendar.vue"),
              meta: {
                title: "Work Orders",
                roles: [""]
              }
            },
            {
              path: "list",
              name: "ppm-list-view",
              component: () => import("./ListView/PPMList.vue"),
              meta: {
                title: "Work Orders",
                roles: [""]
              }
            }
          ]
        },
        {
          path: "create",
          name: "ppm-create",
          component: () => import("./CreatePPM/CreatePPM.vue"),
          meta: {
            title: "create",
            roles: [""]
          }
        }
      ]
    }
  ]
};
