export default {
  routes: [
    {
      path: "/unit-residents",
      name: "unit-residents",
      redirect: "/",
      meta: {
        title: "Unit Residents",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "unit-residents-list",
          component: () => import("./UnitResidentsList.vue")
        },
        {
          path: "create",
          name: "create-unit-resident",
          component: () => import("./NewUnitResident.vue")
        },
        {
          path: ":id/edit",
          name: "edit-unit-resident",
          props: true,
          component: () => import("./NewUnitResident.vue"),
          meta: {
            roles: [""],
            isEdit: true
          }
        },
        {
          path: ":id/details",
          name: "unit-resident-details",
          props: true,
          component: () => import("./UnitResidentDetails.vue")
        }
      ]
    }
  ]
};
