export const extractKeyword = (url) => {
  // Adjusted pattern to match both munjz.com.sa and localhost URLs
  const pattern =
    /https:\/\/(?:www\.)?(admin|vendor)\.(munjz\.com\.sa|localtest\.me)/;

  // Use the regular expression to extract the keyword
  const match = url.match(pattern);

  if (match && match[1]) {
    return match[1]; // Return the first capturing group (admin or vendor)
  } else {
    return "connect"; // Return null if no match is found
  }
};
