export default {
  routes: [
    {
      path: ":id",
      name: "show-teams",
      component: () => import("./WrapperShowTeams.vue"),
      meta: {
        title: "Teams",
        roles: [""],
      },
      redirect: { name: "show-teams-details" },
      children: [
        {
          path: "details",
          name: "show-teams-details",
          component: () => import("./ShowTeamsDetails.vue"),
          meta: {
            title: "Teams",
            roles: [""],
          },
        },
        {
          path: "members",
          name: "show-teams-members",
          component: () => import("./ShowTeamsMembers.vue"),
          meta: {
            title: "Teams",
            roles: [""],
          },
        },
      ],
    },
  ],
};
