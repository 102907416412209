import showCategoryRoute from "./show.category.route";

export default {
  routes: [
    {
      path: "/pricebook",
      name: "pricebook-index",
      component: () => import("./WrapperPricebook.vue"),
      meta: {
        title: "Library",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "pricebook",
          component: () => import("./PricebookList.vue")
        },
        {
          path: "create",
          name: "create-pricebook",
          component: () => import("./CreatePricebook.vue")
        },
        {
          path: "edit/:id",
          name: "edit-pricebook",
          component: () => import("./CreatePricebook.vue"),
          meta: {
            isEdit: true
          }
        },
        {
          path: "",
          name: "show-category-index",
          component: () => import("./IndexShowCategory.vue"),
          redirect: { name: "show-category" },
          children: [...showCategoryRoute.routes]
        }
      ]
    }
  ]
};
