export default {
  routes: [
    {
      path: "operation-expenses",
      name: "operation-expenses",
      component: () => import("./OperationExpenses.vue"),
      meta: {
        title: "operation-expenses",
        roles: [""],
      },
    },
    {
      path: "asset-expenses",
      name: "asset-expenses",
      component: () => import("./AssetExpenses.vue"),
      meta: {
        title: "asset-expenses",
        roles: [""],
      },
    },
  ],
};
