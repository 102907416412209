import budgetingRoute from "./Budgeting/budgeting.route";
import expensesRoute from "./Expenses/expenses.route";
import reportingRoute from "./Reporting/reporting.route";

export default {
  routes: [
    {
      path: "/accounting",
      name: "accounting",
      component: () => import("./WrapperAccounting.vue"),
      meta: {
        title: "accounting",
        roles: [""]
      },
      redirect: { name: "accounting-dashboard" },
      children: [
        {
          path: "dashboard",
          name: "accounting-dashboard",
          component: () =>
            import("./AccountingDashboard/AccountingDashboard.vue"),
          meta: {
            title: "accounting-dashboard",
            roles: [""]
          }
        },
        {
          path: "expenses",
          name: "accounting-expenses",
          component: () => import("./Expenses/WrapperExpenses.vue"),
          meta: {
            title: "expenses",
            roles: [""]
          },
          redirect: { name: "operation-expenses" },
          children: [...expensesRoute.routes]
        },
        {
          path: "income",
          name: "accounting-income",
          component: () => import("./Income/Income.vue"),
          meta: {
            title: "income",
            roles: [""]
          }
        },
        {
          path: "budgeting",
          name: "accounting-budgeting",
          component: () => import("./Budgeting/WrapperBudgeting.vue"),
          meta: {
            title: "budgeting",
            roles: [""]
          },
          redirect: { name: "budget-settings" },
          children: [...budgetingRoute.routes]
        },
        {
          path: "reporting",
          name: "accounting-reports",
          component: () => import("./Reporting/Reporting.vue"),
          meta: {
            title: "reports",
            roles: [""]
          },
          redirect: { name: "pl-statements" },
          children: [...reportingRoute.routes]
        },
        {
          path: "statement",
          name: "accounting-statement",
          component: () => import("./AccountStatement/AccountStatement.vue"),
          meta: {
            title: "accounting-statement",
            roles: [""]
          }
        },
        {
          path: "invoice",
          name: "invoice",
          component: () => import("./InvoiceTemplate/InvoiceTemplate.vue"),
          meta: {
            title: "invoice",
            roles: [""]
          }
        }
      ]
    },
    {
      path: "create-invoice-template",
      name: "create-invoice-template",
      component: () => import("./InvoiceTemplate/CreateInvoiceTemplate.vue"),
      meta: {
        title: "Create Invoice Template",
        roles: [""]
      }
    }
  ]
};
