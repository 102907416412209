export default {
  routes: [
    {
      path: ":id",
      name: "show-property",
      component: () => import("./WrapperShowProperty.vue"),
      meta: {
        title: "property",
        roles: [""],
      },
      redirect: { name: "show-property-details" },
      children: [
        {
          path: "details",
          name: "show-property-details",
          component: () => import("./ShowPropertyDetails.vue"),
          meta: {
            title: "Property",
            roles: [""],
          },
        },
        {
          path: "ownership",
          name: "show-property-ownership",
          component: () => import("./ShowPropertyOwnership.vue"),
          meta: {
            title: "Property",
            roles: [""],
          },
        },
        {
          path: "units",
          name: "show-property-units",
          component: () => import("./ShowPropertyUnits.vue"),
          meta: {
            title: "Property",
            roles: [""],
          },
        },
        {
          path: "work-orders",
          name: "show-property-work-orders",
          component: () => import("./ShowPropertyWorkOrder.vue"),
          meta: {
            title: "Property",
            roles: [""],
          },
        },
      ],
    },
  ],
};
