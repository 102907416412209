export default {
  routes: [
    {
      path: "/marketplace",
      name: "marketplace-index",
      component: () => import("./MarketPlaceIndex.vue"),
      meta: {
        title: "Marketplace",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "marketplace",
          component: () => import("./WrapperMarketplace.vue")
        },
        {
          path: "create",
          name: "create-marketplace",
          component: () => import("./CreateMarketplace.vue"),
          meta: {
            title: "Create Marketplace",
            roles: [""]
          }
        }
      ]
    }
  ]
};
