export default {
  routes: [
    {
      path: "/locations",
      name: "Locations",
      component: () => import("./WrapperAllLocations.vue"),
      meta: {
        title: "Locations",
        roles: [""]
      }
    }
  ]
};
