export default {
  routes: [
    {
      path: "/property-owners",
      name: "property-owners",
      redirect: "/",
      meta: {
        title: "Property Owners",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "property-owners-list",
          component: () => import("./PropertyOwnersList.vue")
        },
        {
          path: "create",
          name: "create-property-owners",
          component: () => import("./NewPropertyOwner.vue")
        },
        {
          path: ":id/edit",
          name: "edit-property-owners",
          props: true,
          component: () => import("./NewPropertyOwner.vue"),
          meta: {
            roles: [""],
            isEdit: true
          }
        },
        {
          path: ":id/details",
          name: "property-owners-details",
          props: true,
          meta: {
            navigationPath: ["Property Owners", "Hameed Quadri"]
          },
          component: () => import("./PropertyOwnerDetails.vue")
        }
      ]
    }
  ]
};
