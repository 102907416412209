export default {
  routes: [
    {
      path: "/work-order-template",
      name: "work-order-template-index",
      component: () => import("./WrapperOrderTemplate.vue"),
      meta: {
        title: "work-order-template",
        roles: [""]
      },
      children: [
        {
          path: "",
          name: "work-order-template",
          component: () => import("./OrdersTemplates.vue"),
          meta: {
            title: "Library",
            roles: [""]
          }
        },
        {
          path: "edit/:id",
          name: "edit-template",
          component: () => import("./EditTemplate.vue"),
          meta: {
            title: "Library",
            roles: [""]
          }
        }
      ]
    }
  ]
};
