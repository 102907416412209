import billingRoute from "./Billing/billing.route";

export default {
  routes: [
    {
      path: "/administration",
      name: "administration",
      component: () => import("./WrapperAdministration.vue"),
      meta: {
        title: "administration",
        roles: [""]
      },
      redirect: { name: "subscription-plan" },
      children: [
        {
          path: "subscription",
          name: "subscription-plan",
          component: () => import("./SubscriptionPlan/SubscriptionPlan.vue"),
          meta: {
            title: "subscription-plan",
            roles: [""]
          }
        },
        {
          path: "account-settings",
          name: "account-settings",
          component: () => import("./AccountSettings/AccountSettings.vue"),
          meta: {
            title: "account-settings",
            roles: [""]
          }
        },
        {
          path: "billing",
          name: "billing",
          component: () => import("./Billing/WrapperBilling.vue"),
          meta: {
            title: "billing",
            roles: [""]
          },
          redirect: { name: "invoices" },
          children: [...billingRoute.routes]
        }
      ]
    }
  ]
};
