export default {
  routes: [
    {
      path: "details",
      name: "corrective-details",
      component: () => import("./ShowCorrectiveDetails.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "activity-logs",
      name: "activity-logs",
      component: () => import("./ShowCorrectiveActivity.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "procedure",
      name: "procedure",
      component: () => import("./ShowCorrectiveProcedure.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
  ],
};
